<template>
  <b-container class="mt-4" fluid>
    <b-row>
        <b-col class="d-flex align-items-center">
            <h3>{{ translations.page_title }}</h3>
        </b-col>
    </b-row>

    <b-row class="mt-3">
      <b-col cols="12">
        <b-tabs content-class="mt-3">
          <b-tab :title="translations.tabs.account_statements" active>
            <b-row class="mt-3">
              <b-col cols="12">
                <statements-list/>
              </b-col>
            </b-row>
          </b-tab>
          <b-tab :title="translations.tabs.monthly_account_statements">
            <b-row class="mt-3">
              <b-col cols="12">
                <statement-summary-mergeList/>
              </b-col>
            </b-row>
          </b-tab>
        </b-tabs>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import translations from '@/translations';
import StatementsList from './list.vue';
import StatementSummaryMergeList from './statementSummaryMergeList.vue';

export default {
  components: {
    StatementsList,
    StatementSummaryMergeList,
  },
  name: 'FinanceStatementsPage',
  data() {
    return {
      translations: translations.finance.statements,
    };
  },
};
</script>
