<template>
  <div class="p-3">
    <div class="d-flex align-items-center justify-content-between mr-3">
      <statement-filters
        ref="search"
        :loading="loading"
        @reset-page="resetCurrentPage"
        @apply-filters="applyFilters"></statement-filters>
      <b-dropdown id="export-statement-dropdown" :text="translations.statements.export" class="mt-2">
        <b-dropdown-item id="export-statements-details-zip" @click="() => this.exportZip('details')">
          <feather type="download"></feather> {{ translations.statements.global.export_details_zip }}
        </b-dropdown-item>
        <b-dropdown-item id="export-statements-summary-zip" @click="() => this.exportZip('summary')">
          <feather type="download"></feather> {{ translations.statements.global.export_summary_zip }}
        </b-dropdown-item>
        <b-dropdown-item id="export-statements-summary-merge-csv" @click="exportStatementsSummaryMergeCsv">
          <feather type="file"></feather> {{ translations.statements.global.export_summary_merge_csv }}
        </b-dropdown-item>
        <b-dropdown-item id="export-statement-fees-vendor-bill-csv" @click="() => this.exportStatementFees('vendor_bill')">
          <feather type="file"></feather> {{ translations.statements.global.export_statement_fees_vendor_bill }}
        </b-dropdown-item>
      </b-dropdown>
    </div>

    <financial-list
      ref="list"
      v-model="currentPage"
      :header-data="TABLE_HEADERS"
      :events="TABLE_EVENTS"
      :data="statements"
      :disabled="loading"
      :items-per-page="itemsPerPage"
      :total="total"
      :no-items-message="translations.statements.statements_table.empty_statements_list"
      itemRefKey="id"
      selectable
      @page-changed="loadStatementsList"
      @row-details="() => {}"
      @row-export-details="exportTransactions"
      @row-export-summary="exportSummary"
      @row-export-837-file="export837File"
      @row-export-external-claims-report="exportExternalClaimsReport"
      @select-changed="onSelectChange"/>
  </div>
</template>

<script>
import moment from 'moment';
import FinancialList, { associateHeaderDataItem } from '@/components/Financial/FinancialList.vue';
import translations from '@/translations';
import { formatDate, formatDatetime, formatValue } from '@/helpers/finance';
import utils from '@/scripts/tools/utils';
import StatementFilters from './StatementFilters.vue';

export default {
  components: { FinancialList, StatementFilters },
  name: 'FinanceStatementsList',
  created() {
    this.TABLE_HEADERS = [
      associateHeaderDataItem(this.translations.statements.statements_table.id, 'id'),
      associateHeaderDataItem(this.translations.statements.statements_table.client_name, 'client_name'),
      associateHeaderDataItem(this.translations.statements.statements_table.start_date, 'start_date'),
      associateHeaderDataItem(this.translations.statements.statements_table.end_date, 'end_date'),
      associateHeaderDataItem(this.translations.statements.statements_table.balance, 'total_balance'),
      associateHeaderDataItem(this.translations.statements.statements_table.created_at, 'created_at'),
      associateHeaderDataItem(this.translations.statements.statements_table.billing_type, 'billing_type'),
      associateHeaderDataItem(this.translations.statements.statements_table.payer, 'statement_configuration.payer.payer_name'),
      associateHeaderDataItem(this.translations.statements.statements_table.billing_period, 'billing_period'),
    ];
    this.TABLE_EVENTS = {
      'row-details': {
        title: translations.finance.financial_list.icons.details,
        icon: 'eye',
        redirectLink: id => `/finance/statements/${id}`,
      },
      'row-export-details': {
        title: this.translations.statements.global.export_details,
        icon: 'download',
      },
      'row-export-summary': {
        title: this.translations.statements.global.export_summary,
        icon: 'file',
      },
      'row-export-837-file': {
        title: this.translations.statements.global.export_837_file,
        icon: 'file-text',
      },
      'row-export-external-claims-report': {
        title: this.translations.statements.global.export_external_claims_report,
        icon: 'file-text',
        show_if: item => item.statement_configuration && item.statement_configuration.generate_external_claims_report,
      },
    };
  },
  data() {
    return {
      translations: translations.finance,
      appliedFilters: {},
      loading: true,
      statements: [],
      itemsPerPage: 10,
      currentPage: 1,
      total: 0,
      selected: {
        selectAll: false,
        selectedIds: [],
      },
    };
  },
  beforeMount() {
    const page = this.$route.query?.page ? Number(this.$route.query.page) : 1;
    this.currentPage = page;
    this.total = page * this.itemsPerPage;
  },
  mounted() {
    const existFilters = this.checkFilters();
    if (!existFilters) {
      this.loadStatementsList(this.currentPage);
    }
  },
  methods: {
    checkFilters() {
      const { query } = this.$route;
      if (query?.client_id || query?.created_at_start || query?.start_date_from) {
        const filters = { client_id: query.client_id || null };

        if (query.created_at_start) {
          filters.created_at = {
            start: query.created_at_start,
            end: query.created_at_end || null,
          };
        }

        if (query.start_date_from) {
          filters.start_end_date = {
            start: query.start_date_from,
            end: query.end_date_until || null,
          };
        }

        this.$refs.search.recoverFiltersFromURL(filters);
        this.applyFilters(filters);

        return true;
      }

      return false;
    },
    resetCurrentPage() {
      this.currentPage = 1;
      this.total = this.currentPage * this.itemsPerPage;
    },
    setParamsToQuery() {
      const q = {
        ...this.appliedFilters,
        page: this.currentPage > 1 ? this.currentPage : undefined,
      };

      const newQuery = new URLSearchParams(q).toString();
      const currentQuery = new URLSearchParams(this.$route.query).toString();

      if (newQuery !== currentQuery) {
        this.$router.replace({
          query: q,
        });
      }
    },
    applyFilters(filters) {
      this.$refs.list.clearSelection();
      this.appliedFilters = { client_id: filters.client_id };

      if (filters.created_at) {
        this.appliedFilters.created_at_start = filters.created_at.start;
        this.appliedFilters.created_at_end = filters.created_at.end;
      }

      if (filters.start_end_date) {
        this.appliedFilters.start_date_from = filters.start_end_date.start;
        this.appliedFilters.end_date_until = filters.start_end_date.end;
      }

      this.loadStatementsList(this.currentPage);
    },
    onSelectChange(value) {
      this.selected = value;
    },
    async loadStatementsList(page) {
      this.loading = true;
      this.setParamsToQuery();

      const offset = (page - 1) * this.itemsPerPage;
      const limit = this.itemsPerPage;
      try {
        const { data: { data: apiStatements } } = await this.$store
          .dispatch('Financial/getStatements', {
            limit,
            offset,
            order_by: 'end_date',
            order_descending: true,
            ...this.appliedFilters,
          });
        if (!apiStatements) {
          this.total = 0;
          this.statements = [];
          this.currentPage = 1;
        } else {
          const shouldRefetch = apiStatements.total > 0 && this.total > (apiStatements.total + limit);
          this.total = apiStatements.total;
          this.statements = apiStatements.statements;

          if (shouldRefetch) {
            this.currentPage = Math.ceil(this.total / limit);
            this.loadStatementsList(this.currentPage);
            return;
          }
        }
        this.statements.forEach(statement => {
          statement.start_date = formatDate(statement.start_date);
          statement.end_date = formatDate(statement.end_date);
          statement.created_at = formatDatetime(statement.created_at);
          statement.total_balance = formatValue(statement.total_balance);
        });
      } catch (_) {
        this.$noty.error(`${this.translations.statements.errors.fetch_list_error}.`);
      } finally {
        this.loading = false;
      }
    },
    async exportTransactions(id, stmt) {
      try {
        const { data } = await this.$store.dispatch('Financial/exportTransactions', { statement_id: id });

        const dateStr = formatDate(new Date(), 'YYYYMMDD_HHmmss');
        const fileName = `statement_transactions_export_${dateStr}`
        + `_${id}_${stmt.client_name.replace(/\s/g, '_')}.csv`;
        utils.downloadFile(data, fileName);
      } catch (error) {
        let errorMsg = this.translations.global.errors.export_csv;
        if (error?.response?.data?.error?.detail) {
          errorMsg = `${errorMsg}: ${error.response.data.error.detail}`;
        }
        this.$noty.error(errorMsg);
      }
    },
    async exportSummary(id, stmt) {
      try {
        const { data } = await this.$store.dispatch('Financial/exportStatementSummary', { id });

        const dateStr = formatDate(new Date(), 'YYYYMMDD_HHmmss');
        const fileName = `summary_${dateStr}_${id}_${stmt.client_name.replace(/\s/g, '_')}.csv`;
        utils.downloadFile(data, fileName);
      } catch (error) {
        let errorMsg = this.translations.global.errors.export_csv;
        if (error?.response?.data?.error?.detail) {
          errorMsg = `${errorMsg}: ${error.response.data.error.detail}`;
        }
        this.$noty.error(errorMsg);
      }
    },
    async exportZip(type) {
      try {
        this.appliedFilters.type = type;
        const { data } = await this.$store.dispatch('Financial/exportStatementsDetailsZip', {
          ...this.appliedFilters,
          select_all: this.selected.selectAll,
          selected_ids: String(this.selected.selectedIds),
        });

        const dateStr = formatDate(new Date(), 'YYYYMMDD_HHmmss');
        const fileName = `statements_export_${dateStr}.zip`;
        utils.downloadFile(data, fileName);
      } catch (error) {
        let errorMsg = this.translations.global.errors.export_zip;
        if (error?.response?.data?.error?.detail) {
          errorMsg = `${errorMsg}: ${error.response.data.error.detail}`;
        }
        this.$noty.error(errorMsg);
      }
    },
    async exportStatementsSummaryMergeCsv() {
      try {
        const { data } = await this.$store.dispatch('Financial/exportStatementSummaryMerge', {
          ...this.appliedFilters,
          select_all: this.selected.selectAll,
          selected_ids: String(this.selected.selectedIds),
        });
        const fileName = `statements_summary_merge_${moment().utc().format('YYYYMMDD_HHmmss')}.csv`;
        utils.downloadFile(data, fileName);
      } catch (error) {
        let errorMsg = this.translations.global.errors.export_csv;
        if (error.response && error.response.data && error.response.data.error && error.response.data.error.detail) {
          errorMsg = `${errorMsg}: ${error.response.data.error.detail}`;
        }
        this.$noty.error(errorMsg);
      }
    },
    async exportStatementFees(billingMode) {
      try {
        const filters = this.appliedFilters;
        filters.billing_mode = billingMode;
        const { data } = await this.$store.dispatch('Financial/exportStatementFees', {
          ...filters,
          select_all: this.selected.selectAll,
          selected_ids: String(this.selected.selectedIds),
        });
        const fileName = `statement_fees_${moment().utc().format('YYYYMMDD_HHmmss')}.csv`;
        utils.downloadFile(data, fileName);
      } catch (error) {
        let errorMsg = this.translations.global.errors.export_csv;
        if (error.response && error.response.data && error.response.data.error && error.response.data.error.detail) {
          errorMsg = `${errorMsg}: ${error.response.data.error.detail}`;
        }
        this.$noty.error(errorMsg);
      }
    },
    async export837File(id, statement) {
      try {
        const { data } = await this.$store.dispatch('Financial/export837File', { statement_id: id });
        const fileName = this.makeExport837Filename(statement);
        utils.downloadFile(data, fileName);
      } catch (error) {
        let errorMsg = this.translations.global.errors.export_837_file_generic;
        if (error.response && error.response.status === 404) {
          errorMsg = this.translations.global.errors.export_837_file_not_available;
        }
        this.$noty.error(errorMsg);
      }
    },
    async exportExternalClaimsReport(id) {
      try {
        const { data } = await this.$store.dispatch('Financial/exportExternalClaimsReport', { statement_id: id });
        const fileName = `external_claims_report_${moment().utc().format('YYYYMMDD_HHmmss')}.csv`;
        utils.downloadFile(data, fileName);
      } catch (error) {
        let errorMsg = this.translations.statements.errors.export_external_claims_report_generic;
        if (error.response && error.response.status === 404) {
          errorMsg = this.translations.statements.errors.export_external_claims_report_not_available;
        }
        this.$noty.error(errorMsg);
      }
    },
    makeExport837Filename(statement) {
      const clientName = `_${statement?.client_name}` || '';
      const payerName = `_${statement?.statement_configuration?.payer?.payer_name}` || '';
      const statementID = `_${statement?.id}` || '';
      const date = `_${moment(statement.created_at).utc().format('YYYYMMDD')}` || '';

      let fileExtension = 'txt';
      if (statement?.statement_configuration?.clearing_house === 'waystar') {
        fileExtension = 'CLP';
      }

      return `SwordHealth_Claims${clientName}${payerName}${statementID}${date}.${fileExtension}`;
    },
  },
};
</script>
